
// @import url("./assets/lib/hljs/styles/atom-one-light.css");
// @import url("../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css");
// @import url("../node_modules/@fortawesome/fontawesome-free/css/all.min.css");
@import "./assets/scss/app.scss";
// @import "~ng-zorro-antd/style/index.min.css";
// @import "~ng-zorro-antd/button/style/index.min.css";
// @import "~leaflet/dist/leaflet.css";
// @import "../node_modules/leaflet.fullscreen/Control.FullScreen.css";
// @import "../node_modules/leaflet-measure/dist/leaflet-measure.css";
// @import "../src/app/apps/maps/leaflet-ruler.css";
// @import "../src/app/apps/maps/Leaflet.Coordinates-0.1.5.css";
@import './assets/css/leaflet.draw.css';
@import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

html,
body {
    height: 100%;
}

body {
    margin     : 0;
    // font-family: Roboto, "Helvetica Neue", sans-serif;
    font-family: Inter, sans-serif;
}

// -----------------------------------------------------------------------------------------
.header {
    box-shadow   : inset 0 0 1px 1px hsla(0, 0%, 100%, .9), 0 20px 27px 0 rgba(0, 0, 0, .05) !important;
    border-bottom: 0px !important;
    // height       : auto !important
}

.header .logo {
    border-right: 0px solid #f8f9fa !important;
    margin      : auto;
    width: 180px;
}

// .desktop-toggle {
//     color    : #252f40 !important;
//     padding  : 1px 12px !important;
//     font-size: 23px !important;

//     a:hover,
//     a:focus,
//     a:active {
//         color     : #1a237e !important;
//         box-shadow: 0 .25rem .375rem -.0625rem hsla(0, 0%, 8%, .12), 0 .125rem .25rem -.0625rem hsla(0, 0%, 8%, .07) !important;
//     }
// }

// .side-nav {
//     top: 80px;
// }

// perfect-scrollbar>.ps {
//     background: #f8f9fa;
// }

.ant-menu-inline .ant-menu-item {
    // height: 35px !important;
    // line-height: 35px !important;
    // height: 40px !important;
    // line-height: 40px !important;

    height: 50px !important;
    line-height: 50px !important;
}
.ant-menu-sub.ant-menu-inline>.ant-menu-item, .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
    list-style-position: inside;
    list-style-type: disc;
    height: 35px !important;
    line-height: 35px !important;
    padding: 0px 0px 0px 0px;
    margin: 2px 0px 0px 0px;
    /* width: 95%; */
    padding: 0px !important;
}

.ant-menu:not(.ant-menu-dark) a {
    letter-spacing: 0.1px;
    font-weight: 400;
    color: #252f40;
    font-size: .9rem;
    letter-spacing: .2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: #ffffff; 
    // color: #252f40;
    // background-color: #fff !important;
    background-color: #f76d6d !important;
    font-weight: 500;
    // box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
    border-radius: .5rem;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
    // color: #ffffff!important;
    color: #4c96d1 !important;
    border-radius: 6px;
    font-weight: 500;
    padding-left: 0px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover {
    color: #4c96d1 !important;
    border-radius: 6px;
    font-weight: 500;
}
.ant-menu-inline .ant-menu-item:hover {
    color: #4c96d1 !important;
    border-radius: 0.5rem;
    font-weight: 500;
    // background-color: #fff !important;
    // background-color: #152d41 !important;
    // background-color: #325b7c !important;
    background-color: #2b3545 !important;
    font-weight: 500;
    .sdeMnuIcn {
        color: #ffffff !important;
    }
    // box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);

}

.prflesdenav{
    .ant-menu-inline .ant-menu-item {
        height: 35px !important;
        line-height: 35px !important;
    }
    .ant-menu-sub.ant-menu-inline>.ant-menu-item, .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
        list-style-position: inside;
        list-style-type: disc;
        height: 35px !important;
        line-height: 35px !important;
        padding: 0px 0px 0px 0px;
        margin: 2px 0px 0px 0px;
        /* width: 95%; */
        padding: 0px !important;
    }
    
    .ant-menu:not(.ant-menu-dark) a {
        letter-spacing: 0.1px;
        font-weight: 400;
        color: #252f40;
        font-size: .9rem;
        letter-spacing: .2px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        // color: #252f40;
        // background-color: #fa0000 !important;
        color: #ffffff;
        background-color: #3c9ef0 !important;
        font-weight: 500;
        // box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
        border-radius: .5rem;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
        // color: #ffffff!important;
        color: #4c96d1 !important;
        border-radius: 6px;
        font-weight: 500;
        padding-left: 0px;
    }
    
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover {
        color: #4c96d1 !important;
        border-radius: 6px;
        font-weight: 500;
    }
    .ant-menu-inline .ant-menu-item:hover {
        color: #4c96d1 !important;
        border-radius: 0.5rem;
        font-weight: 500;
        // background-color: #fff !important;
        // background-color: #152d41 !important;
        background-color: #325b7c !important;
        font-weight: 500;
        .sdeMnuIcn {
            color: #ffffff !important;
        }
        // box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
    
    }
    .ps {
        background: white;
        opacity: 1;
    }
    
}

.main-content {
    padding: calc(81px + 10px) 25px 0px 25px;
    min-height: calc(100vh - 40px) !important;
    // background: #f8f9fa !important;
    background: #f0f1f7!important;
}

@media only screen and (min-width:1000px) and (max-width:1500px){
    .main-content {
    padding: calc(75px + 10px) 25px 0px 25px !important;
    }
}


.ftrDvlpd {
    // height    : 5vh;
    width     : 100%;
    // background: #1c1d2b;
    background: #34425a;

    .logo {
        width: 100%;
    }

    // p {
    //     margin-top: 10px;
    // }

    img {
        width     : 60%;
        margin    : 2% 10%;
        padding   : 0px;
        height    : 5vh;
        width     : 80%;
        object-fit: contain;
        background: white;
    padding: 10px;
    margin: 0px auto;
    margin-left: 17px;
    border-radius: 3px;
    }
}

// .ant-dropdown-menu-item>a,
// .ant-dropdown-menu-submenu-title>a {
//     margin: 0px 0px;
//     width : fit-content;
// }

.hdr_mdl {
    img {
        width : 80px;
        height: 80px;
    }

    span:nth-child(2) {
        padding: 0px 0px 0px 10px;
        margin : auto;

        h1 {
            margin                 : 0px;
            font-weight            : 700;
            font-size              : 26px;
            text-align             : center;
            letter-spacing         : 1px;
            text-transform         : uppercase;
            background             : -webkit-linear-gradient(#399dff, #1438e2);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow            : 3px 3px 2px rgb(0 0 0 / 14%);
        }

        h2 {
            margin        : 0px;
            font-weight   : 700;
            font-size     : 17px;
            text-align    : center;
            color         : #545454;
            letter-spacing: 1px;
            line-height   : 15px;
            text-transform: uppercase;
        }
    }
}

.common-layout .page-container {
    padding-left: 230px;
}
::ng-deep.ant-select{
    border: 1px solid #d9d9d9;
    border-radius: 3px
}

.shwMbl{
    display: none !important;
}
.hdeSdcntr{
    display: block;
}
.shwSdcntr{
    display: block;
}

// -----------------------------------------------------------------------------------------


#tripsTrackedChart g,
#distanceTavelledChart g,
#tripsTavelledChart g,
#tripsCtgryWseChart g,
#distanceCtgryWseChart g,
#lst10DysNtoprdTrpsChart g,
#lastSevenDaysChart g,
#lastSevenDaysDistanceChart g,
#distanceChart,
#tripChart {
    g:nth-child(2) {
        g:nth-child(2) {
            g {
                g:nth-child(3) {
                    g:first-child {
                        rect {
                            display: none !important;
                        }
                    }

                    g:nth-child(2) {
                        g {
                            path {
                                display: none !important;
                            }
                        }
                    }
                }
                g:nth-child(4) {
                    g:first-child {
                        rect {
                            display: none !important;
                        }
                    }

                    g:nth-child(2) {
                        g {
                            path {
                                display: none !important;
                            }
                        }
                    }
                }
                g:nth-child(5) {
                    g:first-child {
                        rect {
                            display: none !important;
                        }
                    }

                    g:nth-child(2) {
                        g {
                            path {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

// #ctgrytripsTavelledChart g{
//     g:nth-child(2){
//         g:nth-child(2){
//             g{
//                 g:nth-child(3){
// g:first-child{
//     rect{
//         display: none!important;
//     }
// }
//                     g:nth-child(2) g{
//                         g{
//                             path{
//                                 display: none!important;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }

// }

#chartdiv g {

    g:nth-child(2) {
        g:nth-child(2) {
            g {
                g:nth-child(3) {
                    g:first-child {
                        rect {
                            display: none !important;
                        }
                    }

                    g:nth-child(2) {
                        g {
                            path {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
}


#hepachartdiv g{
    g:nth-child(2) {
        g:nth-child(2) {
            g {
                g:nth-child(3) {
                    // g:first-child {
                    //     rect {
                        // }
                    // }

                    g:nth-child(2) {
                        g:nth-child(1) {
                            path {
                                display: none !important;
                            }
                        }
                        g:nth-child(2) {
                                path {
                                    display: none !important;
                                }
                        }
                    }
                }
            }
        }
    }
}

.long-text {
    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    text-overflow     : ellipsis;
}

.ant-layout-header {
    height     : 64px;
    padding    : 0 50px;
    color      : #efeff5;
    line-height: 64px;
    background : #08545a;
}

.WrappedColumnClass {
    white-space: normal;
    word-wrap  : break-word;
}

.mt-20 {
    margin-top: 20px;
}

.ant-row {
    position    : relative !important;
    margin-left : 0;
    margin-right: 0;
    height      : auto;
    zoom        : 1;
    display     : block;
    box-sizing  : border-box;
}

.ant-input-affix-wrapper {
    font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, PingFang SC, Hiragino Sans GB,
        Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size  : 14px;
    line-height: 1.71428571;
    color      : rgba(0, 0, 0, 0.65);
    box-sizing : border-box;
    margin     : 0;
    padding    : 0;
    list-style : none;
    position   : relative;
    display    : inline-block;
    width      : 100%;
}

.ant-form-item {
    font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, PingFang SC, Hiragino Sans GB,
        Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size     : 14px;
    line-height   : 1.71428571;
    color         : rgba(0, 0, 0, 0.65);
    box-sizing    : border-box;
    margin        : 0;
    padding       : 0;
    list-style    : none;
    margin-bottom : 24px;
    vertical-align: top;
}

.form-div-padding {
    padding      : 3rem !important;
    padding-right: 8rem !important;
    padding-left : 8rem !important;
    padding-top  : 16rem !important;
}

body {
    font-size            : 14px;
    line-height          : 1.5715;
    background-color     : #fff;
    font-feature-settings: "tnum";
    // overflow-x: hidden;
    // overflow-y: hidden;
}

.ant-input-affix-wrapper {
    border-radius: 5px !important;
}

.header-logo-text {
    color    : #168e67;
    font-size: 14px;
    font-family: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height   : 28px;
    font-weight   : 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top    : 0px;
    box-shadow    : 0 2px 0 0 rgba(115, 143, 147, 0.12);
    text-shadow   : 1px 1px 0 #ccc;
}

.header-logo-sb-text {
    color         : #5a3e24;
    font-size     : 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight   : 500;
    text-align    : center;
}

.mobile-img {
    display: none;
}

.login-img {
    width: 500px;
}

// .common-layout.is-folded .page-container {
//     .side-nav {
//         width: 95px;
//     }
// }

.login-bg-card {
    height       : 480px;
    flex-grow    : initial;
    display      : block;
    position     : relative;
    background   : #d8f3fe;
    // background: #f0f7ff;
    padding-left : 384px;
    margin       : 22% 0px;
    box-shadow   : rgba(0, 0, 0, 0.05) 0px 2px 4px;
    border-radius: 4px;
}

.login-card {
    flex-grow       : initial;
    position        : absolute;
    left            : 32px;
    top             : -34px;
    width           : 335px;
    height          : calc(100% + 96px);
    box-shadow      : rgba(0, 0, 0, 0.05) 0px 2px 4px;
    border-radius   : 2px;
    margin          : 0px auto;
    flex-grow       : 1;
    height          : 100%;
    max-width       : 100%;
    background-color: white;
    height          : calc(100% + 70px);
    padding         : 30px;
    padding-top     : 10%;
}

.login-div {
    margin-left      : 0% !important;
    margin-right     : 0% !important;
    background-image : url(assets/images/others/login-3.png);
    height           : 100%;
    background-repeat: no-repeat;
    width            : 100%;
    min-width        : 100%;
    max-width        : 100%;
    background-repeat: round;
    background-color : #f6faff;
}

.login-logo-text {
    font-family    : Lato-Bold;
    font-weight    : 700;
    font-size      : 20px;
    color          : rgba(22, 47, 86, 0.87);
    text-decoration: none;
    text-align     : left;
    letter-spacing : 0px;
    line-height    : 67px;
    overflow       : initial;
    text-overflow  : initial;
    max-height     : initial;
    position       : relative;
}

.login-logo-sub-text {
    font-family    : Lato-Regular;
    font-weight    : 400;
    font-size      : 14px;
    color          : rgba(22, 47, 86, 0.54);
    text-decoration: none;
    text-align     : left;
    letter-spacing : 0px;
    line-height    : 15px;
    margin-bottom  : 20px;
    overflow       : initial;
    text-overflow  : initial;
    max-height     : initial;
    position       : relative;
}

.login-card-label {
    font-size     : 20px;
    line-height   : 64px;
    color         : rgba(22, 47, 86, 0.87);
    font-weight   : 700;
    text-transform: capitalize;
}

.copyright {
    text-align: center;
    margin-top: 14%;
}

.main-header-logo {
    // height: 48px;
    // padding-top: 6px;

    // margin-top : -10px;
    // width      : 100%;
    // height     : 100%;
    margin-left: 10px;
}

.main-header-logo-txt {
    font-weight   : 600;
    // color         : #252f40;
    color: #d0d6df;
    // font-size     : 0.9rem;
    font-size: 12px;
    letter-spacing: 1.2px;
    margin        : 0px;
    text-transform: capitalize;
    text-align    : center;
    padding       : 0px;
    width         : 100%;
}



.box {
    background                : #fff;
    margin                    : 14px auto;
    box-shadow                : -4px -3px 10px;
    padding                   : 4px;
    border-top-left-radius    : 3px;
    border-top-right-radius   : 10px;
    border-bottom-left-radius : 3px;
    border-bottom-right-radius: 3px;
    margin-left               : 10px;
    color                     : #8dccd1;
}

.header-border {
    border-bottom: 1px solid #f8f8f8;
    padding      : 6px;
}

.header-icon {
    font-size       : 20px;
    display         : inline-block;
    text-align      : center;
    margin          : 0 12px 0 0;
    line-height     : 40px !important;
    border-radius   : 50%;
    width           : 35px;
    height          : 35px;
    background-color: #fff;
    box-shadow      : 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.header-label {
    // color      : #0d62cb !important;
    // font-weight: 700 !important;
    line-height: 30px;
    font-size  : 18px;
    font-weight: 700;
    font-family: Inter, sans-serif;
    color: #232323 !important;
}

.tblEdtBtn {
    color              : #303f9f;
    // background-color: #e8eaf6;
    // border-color    : #c5cae9;
    padding            : 1px;
    border-radius      : 5px;
    font-weight        : 600;
    // text-transform     : uppercase;
    letter-spacing     : 0px;
    text-align         : center;
    font-size          : 13px;

    p {
        text-align   : center;
        font-size    : 9px;
        margin       : 0px;
        counter-reset: #7986cb;
    }
}

.tblDelBtn {
    // color: #db0e0ec2;
    color              : rgba(219, 14, 14, 0.7607843137254902);
    // background-color: #f4e1e182;
    background-color   : rgba(244, 225, 225, 0.5098039215686274);
    border-color       : transparent;
    border-radius      : 5px;
    padding            : 2px;
    font-weight        : 500;
}

.mb-20 {
    margin-bottom: 20px;
}

.ant-table thead>tr>th {
    color      : #fffefe;
    font-weight: 500;
    background : #5f6cd0 !important;
    text-align : center;
    border     : 1px solid white;
    padding    : 3px;
}

.ant-table tfoot>tr>td,
.ant-table tfoot>tr>th,
.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
    padding: 3px !important;
}

tr.ant-table-row.ng-star-inserted:nth-child(even) {
    background-color: #f1f1f1;
}

.rt {
    text-align: right;
}

.ct {
    text-align: center;
}

.lt {
    text-align: left;
}

.sdeMnu-grp-lbl {
    // text-align: center;
    // background: #f7f9f7;
    // padding: 18px;
    // margin: 10px;
    // padding: 1rem;
    // border-bottom: 3px solid;
    // border-style: solid;
    // border-image: linear-gradient( to bottom, #118791, rgb(251 205 22) ) 1 100%;
    // color: #118791;
    // font-weight: bold;
    // font-size: 14px;
    // padding: 2px 9px 3px 2px !important;
    // display: inline-block;
    // text-align: left;
    // background-color: #f8feff;
    // border-bottom: 1px solid #f6f6f6;
    // margin-left: 24px !important;

    // color: #118791;
    color              : #0d62cb;
    font-weight        : bold;
    font-size          : 14px;
    padding            : 2px 9px 3px 8px !important;
    display            : inline-block;
    text-align         : left;
    /* background-color: #f8feff; */
    border-bottom      : 1px dashed #cccccc;
}

.sdeMnu-grp-lbl:hover {
    color     : #118791 !important;
    cursor    : auto !important;
    background: transparent !important;
}

// .sdeMnu-grp-lbl:after {
//     position: relative;
//     top: 12px;
//     display: block;
//     width: calc(100% - 20px);
//     height: 1px;
//     background: #f0f0f0;
//     content: "";
// }
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 16px 0 15px !important;
}

.leaflet-top {
    z-index: auto !important;
}

.leaflet-bottom {
    z-index: auto !important;
}

.hdrimgndTxt {
    padding-left: 25px;
}

.hdrUsrImg {
    width        : 52px !important;
    height       : 52px !important;
    margin-right: 14px !important;
}

.usrDsgnTxt {
    margin-left: 70px;
    margin-top : -29px;
    font-weight: 500;
    color      : #000000;
}

.shwNmDiv {
    font-size: 12px;
}

// .card_g {

//     .col-lg-6,
//     .col-lg-8,
//     .col-lg-4 {
//         padding: 0px
//     }
// }

.std-card-title {
    font-size: 17px;
    font-weight: 500;
    color: #c4171e;
    background: #fff;
    margin: 0;
    padding: 10px;
    box-shadow: 0 2px 0 0 rgba(115, 143, 147, 0.12);
    text-shadow: 1px 0px 0 #ccc;
}

.ant-card-head-title {
    overflow     : hidden;
    white-space  : nowrap;
    text-overflow: ellipsis;
    font-size    : 18px;
    font-weight  : 500;
    color        : #40548f;
    background   : #fff;
    margin       : 0;
    padding      : 10px;
    box-shadow   : 0 2px 0 0 rgba(115, 143, 147, 0.12);
    text-shadow  : 1px 1px 0 #ccc;
}

.ant-select-selector {
    border-radius: 5px !important;
}

.dx-scrollable-scroll.dx-state-invisible {
    opacity: 1 !important;
}

.dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible .dx-scrollable-scroll-content {
    background-color: rgb(0 0 0 / 28%) !important;
}


.tblVwBtn {
    color           : #2d33e2;
    background-color: rgba(225, 226, 244, 0.521569);
    border-color    : transparent;
    border-radius   : 5px;
    padding         : 2px;
    font-weight     : 500;
}


.ant-menu-inline .ant-menu-item::after {
    border-right: 0px solid #1a237e !important;
}

// .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
//     padding: 0 20px !important;
// }

// .ant-menu-inline-collapsed>.ant-menu-item {
//     padding: 0 0px !important;
// }

.side-nav {
    box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 0%), 0 6.7px 5.3px rgb(0 0 0 / 0%), 0 12.5px 10px rgb(0 0 0 / 0%),
    0 22.3px 17.9px rgb(0 0 0 / 0%), 0 41.8px 33.4px rgb(255 255 255 / 9%), 0 100px 80px rgb(0 0 0 / 9%) !important;
    // transition        : all 0s ease !important;
    // -webkit-transition: all 0s ease !important;
    // width             : 240px;
}



// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover {

//     background: #f6f9ff !important;
//     color: #af1caf!important;
// }

.ant-drawer-header {
    box-shadow: 0 1px 2px 0 rgb(115 143 147 / 40%) !important;
    background: linear-gradient(135deg, #42a5f5 0%, #1976d2 100%) !important;
    border-bottom: 0px solid #f0f0f0 !important;
    padding: 8px 16px !important
}
.ant-drawer-title{
    font-size: 18px !important;
    text-transform: capitalize;
}
.ant-drawer-close{
    padding: 10px 25px !important;
    font-weight: 700;
    font-size: 15px !important;
}
.ant-drawer-body{
    padding: 8px 14px !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.sdeMnuIcn:hover {
    color: #595c97 !important;
}

.ant-drawer-title {
    color: white !important;
}

.anticon-close {
    color: white !important;
}

input {
    border-radius: 5px !important;
}



.is-folded .side-nav .ant-menu-inline-collapsed {
    padding: 0px !important;
}

.ant-menu-submenu-popup {
    background-color: #fff !important;
}

.ant-menu-inline-collapsed.ant-menu-inline .ant-menu-item::after {
    border-right: 0px solid #1a237e !important;
}

.ant-menu:not(.ant-menu-dark) a:hover {
    // color: #990d0d;
    // color: #118791!important;
    // color      : #212121 !important;
    color: #ffffff !important;
  

    font-weight: 500;
    cursor     : pointer;
    // background: white;

}

.ant-menu-inline .ant-menu-submenu-title {
    margin-bottom: 0px !important;
}
.ant-menu-inline-collapsed {
    width: 80px !important;
}

.ant-menu-submenu-title:hover {
    color        : #4527a0 !important;
    font-weight  : 500;
    border-radius: 4px;
}

.ant-menu-inline.ant-menu-item a {
    color         : #050a4e !important;
    font-size     : 15px !important;
    font-weight   : 500;
    letter-spacing: .6px;
}


.ant-menu-inline.ant-menu-item a:hover {
    color: #ad04ad !important;
}

.ant-menu-inline-collapsed> .ant-menu-item a .sdeMnuIcn{
 
    margin-left: 4px !important;
}
.ant-menu-inline-collapsed>.ant-menu-item a i{
   margin-left: 4px!important;
}

.ant-menu-inline-collapsed>.ant-menu-item-selected a i{
    // background: #e8f5e9 !important;
    background: #f76d6d!important;
    color: #ffffff !important;
}

.main-content {
    min-height: calc(100vh - 40px) !important;
}

.footer .footer-content {
    height: 40px !important;
}

.justify-content-between {
    justify-content: center !important;
}

.sbmtBtm {
    margin-left: 4px;
}

.dashboard {
    .clr-crds {
        .card-label {
            font-size     : 13px;
            padding-top   : 0;
            color         : #ffffff;
            padding-bottom: 2%;
        }

        .cardOne {
            border-top      : 2px solid #2967ef;
            background-image: linear-gradient(180deg, #ff8308, #fd4f00);
        }

        .cardTwo {
            border-top      : 2px solid #2967ef;
            background-image: linear-gradient(180deg, #ed629a, #c850c0);
        }

        .cardThree {
            border-top      : 2px solid #2967ef;
            background-image: linear-gradient(180deg, #17cec4, #059890 0%, #08aeea);
        }

        .cardFour {
            border-top      : 2px solid #2967ef;
            background-image: linear-gradient(180deg, #e2946e, #45a259);
        }

        .cardOnehdng {
            color      : #fdfdfd;
            text-shadow: 4px 2px 0 #c2cc;
        }
    }

    .show-sla {
        padding      : 0px;
        margin-top   : -34px;
        text-align   : right;
        margin-bottom: -40px;
    }

    .clr-crds2 {
        .card-count {
            font-size  : 30px;
            font-weight: 500;
            color      : #ffffff;
        }

        .card-label {
            font-size     : 13px;
            padding-top   : 0;
            color         : #ffffff;
            padding-bottom: 2%;
        }

        .cardOne {
            border-top      : 2px solid #2967ef;
            background-color: #673ab7 !important;
        }

        .cardTwo {
            border-top      : 2px solid #2967ef;
            background-color: #4caf50 !important;
        }

        .cardThree {
            border-top      : 2px solid #2967ef;
            background-color: #3f51b5 !important;
        }

        .cardFour {
            border-top      : 2px solid #2967ef;
            background-color: #009688 !important;
        }

        .cardFive {
            border-top      : 2px solid #2967ef;
            background-color: #fa8c16 !important;
        }

        .cardSix {
            border-top      : 2px solid #2967ef;
            background-color: #9c27b0 !important;
        }

        .cardOnehdng {
            color: #fdfdfd;
        }
    }

    .crd-gp-hrd {
        // text-transform: uppercase;
        // letter-spacing: 2px;
        // margin-bottom : -5px;
        // font-weight   : 500;
        text-transform: uppercase;
        letter-spacing: 0px;
        margin-bottom: 2px;
        font-weight: 500;
        margin-left: 10px;
    }

    .crd-gp-hrd2 {
        font-size: 10px;
    }

    .p {
        color: black;
    }

    .head {
        color: #0039e6;
    }

    .hdr {
        background : #564fc1;
        padding-top: 8px;
    }

    .dshbrdTitle {
        color: white;
    }

    .lbltxt {
        padding-left: 10px;
        padding-top : 10px;
        color       : red;
    }

    .count {
        text-align : center;
        font-size  : 20px;
        font-weight: 600;
        color      : #564fc1;
    }

    .txt {
        text-align: center;
    }

    .totlCrds {
        padding: 12px;
    }

    .sbmtBtn,
    .updtBtn {
        background-color: #fff;
        color           : #1a1a79 !important;
    }

    .cnclBtn {
        color           : red !important;
        background-color: #fff;
    }

    .filter-card {
        border-radius   : 0px;
        box-shadow      : 0px 1px 15px 1px rgba(69, 65, 78, 0.08) !important;
        background-color: white;
        padding         : 8px;
        padding-left    : 33px;
        margin-top      : -22px;
        margin-left     : -25px;
        margin-right    : -25px;
    }

    .form-label {
        font-size  : 12px;
        font-weight: 600;
        color      : #424242;
    }

    .filter-box {
        width : 200px;
        margin: 0 10px 8px 0;
    }


    .select-label {
        font-size  : 12px;
        font-weight: 500;
    }

    nz-select {
        margin       : 0 8px 10px 0;
        width        : 100%;
        border-radius: 5px;
    }
    // .slctDsble{
    //     .ant-select-arrow .anticon:not(.ant-select-suffix) {
           
    //         display: none!important;
    //     }
    //   }
 
    .ant-picker {
        width        : 100%;
        margin       : 0 8px 10px 0;
        height       : 32px;
        border-radius: 5px;
    }

    .card-count {
        font-size  : 30px;
        font-weight: 500;
        color      : #212121;
    }

    .card-label {
        font-size     : 13px;
        padding-top   : 0;
        color         : #118791;
        padding-bottom: 2%;
    }

    .dashboard-count-card {
        border-radius   : 6px;
        box-shadow      : 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
        background-color: white;
        text-align      : center;
        padding         : 8px;
    }

    .cardOne {
        border-top: 2px solid #2967ef;
    }

    .cardTwo {
        border-top: 2px solid #f35877;
    }

    .cardThree {
        border-top: 2px solid #8fd623;
        height    : 100%;
    }

    .cardFour {
        border-top: 2px solid #f7a42c;
    }

    .cardOnehdng {
        margin-bottom: 0px !important;
        color        : #2f6bef;
        font-weight  : 700;
    }

    .cardTwohdng {
        color      : #f35877;
        font-weight: 700;
    }

    .cardThreehdng {
        margin-bottom: 0px !important;
        color        : #8fd623;
        font-weight  : 700;
    }

    .dashboard-card {
        border-radius   : 6px;
        box-shadow      : 0px 1px 15px 1px rgba(69, 65, 78, 0.08) !important;
        background-color: white;
        padding         : 8px;
    }

    .border-bottom {
        border-bottom: 1px solid #f6f6f6;
    }

    .card-heading {
        margin-top    : 0;
        margin-bottom : 0;
        font-size     : 16px;
        text-transform: capitalize;
        font-weight   : 700;
        color         : #181c32;
    }

    #monthbox {
        width                     : 15px;
        height                    : 15px;
        border-top-right-radius   : 5px;
        border-bottom-right-radius: 0;
        border-top                : 5px solid #0161f1;
        border-right              : 18px solid #0161f1;
        border-left               : 5px solid transparent;
        border-bottom             : 18px solid transparent;
        float                     : right;
        margin-top                : -7px;
        margin-right              : -6px;
    }

    .p-8 {
        padding: 8px;
    }

    ::ng-deep nz-table.officersTbl .ant-table {
        overflow-x: auto !important;
        height    : 330px !important;
    }

    .tabs-div {
        text-align: right;
        margin-top: 10px;
    }

    .chrgsCls {
        margin-top : 2%;
        font-size  : 16px;
        font-weight: 700;
    }

    .mgnb-2 {
        margin-bottom: 2%;
    }

    .mt-2 {
        margin-top: 2%;
    }

    .hght {
        height: 100% !important;
    }

    .inrSctn {
        border-bottom: 1px solid #e0e0e0;
    }
}
.slctlbl {
    width       : 100%;
    text-align  : left;
    color: #424242;
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
    padding: 0px 0px 2px 4px;
}
.hd-lbl {
    text-decoration: double;
    text-transform : uppercase;
    letter-spacing : 2px;
    padding        : 7px 12px 6px 18px;
    font-weight    : 700;
    color          : #8b19af;
    background     : #f0f1cd;
    width          : 100%;
    margin-bottom  : 20px;
    border-bottom  : solid 2px orange;
}

// ::ng-deep {
.ds-card.grid {
    max-width    : 100%;
    min-width    : 100%;
    border-radius: 8px;
    border       : 0px solid;
    padding      : 10px;
    background   : #fff;
    border-color : rgb(240 240 240) !important;
    border-radius: 8px;
    border       : 1px solid;
    margin-top   : 12px;
    // background: #fffcf7!important;
}

.dx-datagrid .dx-datagid-headers .dx-header-row .dx-datagrid .dx-datagrid-headers .dx-header-filter {
    outline         : none;
    // background: #2c5685;
    // background      : #3190a6;
    background    : #2d82eb !important;
    /* border-bottom: 5px solid #dddddd !important; */
    color           : #ffffff;
    // color: black;
    font-weight     : 900;
    font-size       : 13px;
    margin-right    : 4px;
}

.dx-datagrid .dx-header-filter:before {
    content  : "\f050";
    font-size: 10px;
}

.dx-datagrid .dx-datagrid-headers .dx-header-row>td {
    background    : #1969cd;
    color         : #fff;
    font-weight   : 600;
    font-size     : 13px;
    margin-right  : 4px;
    text-align    : center !important;
    text-transform: capitalize !important;
    outline       : 0;
}

.dx-datagrid-headers {
    color           : #959595;
    font-weight     : normal;
    -ms-touch-action: pinch-zoom;
    touch-action    : pinch-zoom;
    // border-bottom: 5px solid #ddd !important;
}

.dx-datagrid .dx-column-indicators {
    float: right;
}

.dx-datagrid .dx-row-lines>td {
    border-bottom: 1px solid #f5f5f5 !important;
}

.dx-datagrid .dx-row>th {
    background-color: #f9f9f9 !important;
}

.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers+.dx-datagrid-rowsview {
    border-top: 1px solid #f9f9f9;
}

.dx-datagrid-borders>.dx-datagrid-rowsview,
.dx-datagrid-borders>.dx-datagrid-total-footer {
    border-bottom: 1px solid #f9f9f9;
}

.dx-datagrid .dx-column-lines>td {
    border-left : 1px solid #f5f5f5 !important;
    border-right: 1px solid #f5f5f5 !important;
    font-size   : 12px;
    // color: white;
    color       : black;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit {
    width    : 100px;
    min-width: 100px;
    cursor   : pointer;
}

.dx-datagrid .dx-datagrid-headers .dx-header-row>td,
.dx-datagrid .dx-datagrid-headers .dx-header-filter {
    text-align    : center !important;
    text-transform: capitalize !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f0f5fb;
}

.dx-row .dx-column-lines .dx-header-row {
    color    : #fbfcfd;
    // color : black;
}

// .dx-datagrid-rowsview .dx-row {
//     background: rgb(228, 228, 228);
// }
// }

::ng-deep .dx-datagrid-headers .dx-datagrid-table .dx-row>td {
    border-bottom: 1px solid #ddd;
    color        : white !important;
}

::ng-deep .dx-texteditor-input {
    border-radius: 0px;
}

::ng-deep .dx-datagrid-rowsview .dx-row {
    background: #ffffff;
}

::ng-deep .dx-datagrid .dx-row-alt>td,
.dx-datagrid .dx-row-alt>tr>td {
    background-color: #fafafa;
    border-top      : 1px solid #f5f5f5;
    border-bottom   : 1px solid #f5f5f5;
}

::ng-deep .ant-picker {
    width        : 90% !important;
    border-radius: 5px !important;
}

::ng-deep .dx-datagrid-summary-item {
    color: white !important;
}

::ng-deep .dx-datagrid-summary-item {
    font-weight: 600 !important;
}

::ng-deep .ant-select-multiple .ant-select-selection-item {
    position     : relative !important;
    // color: black;
    margin-top   : 0px !important;
    margin-right : 5px !important;
    margin-bottom: 0px !important;
    padding      : 0px 5px 0 5px !important;
    background   : #f5f5f5 !important;
    border       : 0px solid #f0f0f0 !important;
    border-radius: 3px;
}

::ng-deep.ant-select-multiple .ant-select-selection-item-remove {
    // color: inherit !important;
    font-style    : normal;
    text-align    : center;
    text-transform: none;
    vertical-align: -0.125em;
    font-weight   : 600;
    line-height   : inherit;
    cursor        : pointer;
    display       : inline-block;
    font-size     : 10px;
}

::ng-deep .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-menu {
    display: block !important;
}

::ng-deep .dx-datagrid-content .dx-datagrid-table {
    border-collapse: collapse;
    border-spacing : 0;
    background     : #1969cd;
    margin         : 0;
    color          : white;
    cursor         : pointer;
    max-width      : 10px;
}

::ng-deep .dx-datagrid-content .dx-datagrid-table [class*="column"]+[class*="column"]:last-child {
    float           : none;
    background-color: white;
}

.qraclass {
    img {
        width : 100%;
        height: 100%;
    }
}

.permMsg {
    text-align: center;
    font-size : 20px;
    padding   : 30px;
}

.dashboard .card-counts {
    font-size  : 30px;
    font-weight: 500;
    color      : #ffffff;
}

.dashboard .card-labe {
    font-size     : 13px;
    padding-top   : 0;
    color         : #ffffff;
    padding-bottom: 2%;
}

.dashboard .cardOnehdn {
    margin-bottom: 0px !important;
    color        : #ffffff;
    font-weight  : 700;
}

#fr-logo {
    display: none !important;
}

.fr-view p:last-child {
    display: none !important;
}

.fr-view {
    p:last-child {
        display: none !important;
    }
}
.hdrBar{
    // margin: 10px 10px;
    padding: 10px;
    h1{
      margin: 0px;
      font-size: 23px !important;
      text-align: left;
      padding-left: 6px;
      padding-right: .5em;
      background-size: 100% 15%;
      background-repeat: repeat-x;
      background-position: left 0% bottom 10%;
      background-image:linear-gradient(2deg, #e2e2e2 0%, #eaeaea 50%, transparent 54%, transparent 100%);
      width: fit-content;
      color: #d51920;
    }
    // span{
    //   margin: 0px;
    //   padding: 1px 7px 0px 5px!important;
    // font-size: 15px;
    // color: #424242;
    // font-weight: 600;
    // letter-spacing: .6px;
    // }
    .ant-picker{
      background: #ffffff;
    border: 1px solid #4f61d1;
    border-radius: 2px;
    border-radius: 60px;
    transition: border .3s,box-shadow .3s;
    }
    
  }
@media only screen and (min-width: 320px) and (max-width: 850px) {
    .filter-box {
        width: 370px;
    }
    .header-logo-text {
        // width: 396px;
        width        : 410px;
        overflow     : hidden;
        text-overflow: ellipsis;
        white-space  : nowrap;
    }
    .footer {
        padding   : 0px 16px;
        text-align: center;
    }

    // .header-logo-text {
    //     font-size: 14px;
    // }
    .kndukurLogoImg {
        display: none;
    }

    .trmsCndtns {
        display: none;
    }

    .cpyRgt {
        color      : #1c049a;
        font-weight: 500;
    }

    .card_content {
        padding        : 1rem 1.5rem!important;
       }
    //    .common-layout .page-container {
       
    //     // padding-left      : 0px; 
    // }
    .cards_item{
        width: 100%!important;
    }
    .hdrimgndTxt {
        width: auto !important;
    }

    .hdrimgndTxt {
        padding-left: 0px !important;
        font-size   : 12px !important;
    }

    .kndukurLogoImg {
        display: none;
    }
    // .card_g {
    //     .col-lg-6,
    //     .col-lg-8 {
    //         padding: 0px;
    //         width: 70%;
    //     }
    //     .col-lg-4 {
    //         padding: 0px;
    //         width: 30%;
    //     }
    //     .crdHdr_sb{
    //         .col-lg-6:first-child {
    //             padding: 0px;
    //             width: 70%;
    //         }
    //         .col-lg-6:nth-child(2) {
    //             padding: 0px;
    //             width: 30%;
    //         }
    //     }
    //     .crdHdr_sb_sm{
    //         .col-lg-6:first-child {
    //             padding: 0px;
    //             width: 70%;
    //         }
    //         .col-lg-6:nth-child(2) {
    //             padding: 0px;
    //             width: 30%;
    //         }
    //     }
    // }
    .header-logo-text {
        // width: 197px;
        overflow      : hidden;
        text-overflow : ellipsis;
        // white-space: nowrap;
    }

    .login-bg-card {
        margin: 0px !important;
    }

    .login-card {
        left: 0px !important;
        top : 0px !important;
    }

    .login-logo-text {
        display: none;
    }

    .login-logo-sub-text {
        display: none;
    }

    .copyright {
        display: none;
    }

    .login-img {
        display: none;
    }

    .mobile-img {
        display: block;
    }

    .dx-toolbar .dx-toolbar-item:last-child {
        padding  : 0;
        max-width: 34px;
    }
    .header-logo-text {
        width        : 420px;
        overflow     : hidden;
        text-overflow: ellipsis;
        white-space  : nowrap;
    }

    .mobile-img {
        display: none;
    }

    .login-logo-text {
        display: block;
    }

    .login-logo-sub-text {
        display: block;
    }

    .copyright {
        display: block;
    }

    .login-img {
        display: block;
        width  : 350px !important;
    }

    .login-card {
        left: 32px !important;
        top : -34px !important;
    }

    .dx-toolbar .dx-toolbar-item:last-child {
        padding  : 0;
        max-width: 100%;
    }


    .header-logo-text {
        margin-top: 3px !important;
        font-size : 13px;
    }

    .shwNmDiv {
        display: none;
    }

    .hdrUsrImg {
        width : 42px !important;
        height: 42px !important;
    }
    .dx-datagrid-search-panel {
        display: none !important;
    }

    .dx-group-panel-message {
        display: none !important;
    }
    .side-nav {
        left: 0px !important;
        top: 60px !important;
    }
    .shwMbl{
        display: block !important;
    }
    .hdeSdcntr{
        display: none !important;
    }
    .shwSdcntr{
        display: block !important;
    }
    // .common-layout .page-container{
    //     // padding: 0px !important;
    // }
    /* For mobile phones: */
    .hdr_mdl {
        img {
            width : 50px !important;
            height: 50px !important;
        }

        span:nth-child(2) {
            h1 {
                font-size: 15px !important;
            }

            h2 {
                font-size: 10px !important;
            }
        }
    }

    .hdrBar {
        margin: 8px 2px !important;

        h1 {
            font-size   : 15px !important;
            padding-left: 10px !important;
        }

        span {
            padding: 1px 7px 0px 5px!important;
            font-size: 11px !important;
        }

        .ant-picker {
            background: #ffffff;
            border    : 1px solid #4f61d1;
            height    : 30px !important;
            transition: border .3s, box-shadow .3s;
        }

    }

    .main {
        .w-50 {
            width: 50%;
        }

        .cards_item {
            width: 100% !important;
        }

        .card_grand_title {
            font-size  : 11px !important;
            font-weight: 700 !important;
        }

        .card_title {
            font-size  : 14px !important;
            font-weight: 700 !important;
        }

        .card_text {
            font-size    : 10px !important;
            margin-bottom: 2px !important;
            font-weight  : 500 !important;
        }

        .card_sub_title {
            font-size     : 12px !important;
            font-weight   : 700 !important;
            letter-spacing: .2px !important;
            word-break    : break-word !important;
        }

        .card_sub_text {
            font-size    : 10px !important;
            margin-bottom: 0px !important;
            font-weight  : 400 !important;
        }

        .pt-20 {
            padding-top: 22% !important;
        }

        h1 {
            font-size: 14px !important;
        }
        // .card_g {
        //     h1{
        //         font-size: 14px;
        //     }
        //     .crdHdr{
        //         font-size: 15px !important;
        //         width:100%  !important;
              
        //     }
        // }
        .tbl .dx-datagrid .dx-datagrid-headers .dx-header-row>td {
            font-size     : 12px !important;
            padding-top   : .5rem !important;
            padding-bottom: .5rem !important;
        }

        .tbl .dx-datagrid .dx-column-lines>td {
            font-size: 12px !important;
        }

        // .card_content {
        //     padding        : 10px 5px !important;
        // }

        .dx-datagrid-group-panel .dx-group-panel-message {
            display: none !important;
        }
    }
    .header-logo-text {
        margin-top : 8px !important;
        width      : 250px !important;
        font-size  : 11px !important;
        line-height: 18px !important;
        white-space: wrap !important;
        text-align : center !important;
    }

    .header-logo-sb-text {
        font-size: 10px !important;
    }

    .hdrUsrImg {
        margin-top: 0px !important;
    }
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
    .filter-box {
        width: 370px;
    }
    .header-logo-text {
        // width: 396px;
        width        : 410px;
        overflow     : hidden;
        text-overflow: ellipsis;
        white-space  : nowrap;
    }
    #tripsTrackedChart g,
    #distanceTavelledChart g,
    #tripsTavelledChart g,
    #tripsCtgryWseChart g,
    #distanceCtgryWseChart g,
    #lst10DysNtoprdTrpsChart g,
    #lastSevenDaysChart g,
    #lastSevenDaysDistanceChart g,#ctgrytripsTavelledChart,
    #distanceChart,
    #tripChart {
        font-size: 10px !important;
    }
    .slctlbl {
        font-size: 11px !important;
    }
    .crd-hding {
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    .side-nav {
        top: 60px !important;
    }
    .dx-button-has-icon .dx-button-content {
        padding: 5px !important;
    }

    .dx-datagrid-export-button .dx-icon-export-to {
        font: 15px/18px DXIcons !important;
    }

    .ant-picker,
    .ant-picker-input>input {
        font-size  : 11px !important;
        height     : 32px !important;
    }
    //  .common-layout .page-container {
    //         // margin-left: -30px !important;
    //     }
    .menu-full {
        width: 160px !important;
      }
      
      .menu-minified {
        width: 70px !important;
      }
    .side-nav {
        // width: 160px !important;
        .r_sde{
            // width: 160px !important;
        }
        .nav-menu {
            padding-bottom: 20% !important;
            margin-bottom : 20% !important;
            width         : 70px !important;

            .nav-item {
                padding: 2px 4px !important;
                margin : 10px 0px !important;

                p {
                    font-size: 8px !important;

                    span:nth-child(2) {
                        margin-top: -13px !important;
                    }
                }

                h1 {
                    font-size    : 18px !important;
                    margin-bottom: 2px !important;
                }
            }

            .nav-item:hover {
                .nav-sbmenu {
                    .nav-sbitem {
                        div {
                            line-height: 10px !important;

                            span {
                                font-size: 10px !important;
                                line-height: 12px !important;
                            }
                        }
                    }
                }
            }
        }

        .nav-sbmenu {
            .nav-sbitem {
                padding: 8px 70px 8px 16px !important;

                div {
                    line-height: 10px !important;

                    span {
                        font-size: 10px !important;
                    }
                }
            }

            .nav-sbitem:after {
                height: 10px !important;
                width : 10px !important;
                top   : 12px !important;
            }

            .nav-sbitem:before {
                left : 4px !important;
                width: 2px !important;
            }

            .nav-sbitem.active span {
                font-size: 11px !important;
            }
        }
    }

    .hdr_mdl {
        img {
            width : 50px !important;
            height: 50px !important;
        }

        span:nth-child(2) {
            h1 {
                font-size: 16px !important;
            }

            h2 {
                font-size: 10px !important;
            }
        }
    }

    .hdrBar {
        margin: 8px 2px !important;

        h1 {
            font-size   : 15px !important;
            padding-left: 10px !important;
        }

        span {
            padding: 1px 7px 0px 5px!important;
            font-size: 11px !important;
        }

        .ant-picker {
            background   : #ffffff;
            border       : 1px solid #4f61d1;
            border-radius: 2px !important;
            height       : 30px !important;
            transition   : border .3s, box-shadow .3s;
        }

    }

    .inptCls .prmry,
    .inptCls .scndry {
        width  : 10px !important;
        height : 10px !important;
        margin : 0px 5px !important;
        padding: 6px !important;
    }

    .sdr {
        height  : 100vh;
        overflow: auto !important;
    }

    .main {
        .card_grand_title {
            font-size  : 11px !important;
            font-weight: 700 !important;
        }

        .card_title {
            font-size  : 14px !important;
            font-weight: 700 !important;
        }

        .card_text {
            font-size    : 10px !important;
            margin-bottom: 2px !important;
            font-weight  : 500 !important;
        }

        .card_sub_title {
            font-size     : 12px !important;
            font-weight   : 700 !important;
            letter-spacing: .2px !important;
            word-break    : break-word !important;
        }

        .card_sub_text {
            font-size    : 10px !important;
            margin-bottom: 0px !important;
            font-weight  : 400 !important;
        }

        .pt-20 {
            padding-top: 22% !important;
        }

        h1 {
            font-size: 12px !important;
        }
        // .card_g {
        //     h1{
        //         font-size: 11px !important;
        //     }
        //     .crdHdr{
        //         font-size: 12px !important;
        //         width:100%;
        //         // text-align: center !important;
        //         // border-bottom:1px solid white;
        //     }
        // }
        

        .card_content {
            padding        : 5px 15px !important;
            //   background: linear-gradient(to bottom left, #ef8d9c 40%, #ffc39e 100%);
        }

        .dx-datagrid-group-panel .dx-group-panel-message {
            display: none !important;
        }
    }

    .wd-28 {
        width    : 28.888% !important;
        max-width: 28.888% !important;
        flex     : 0 0 28% !important;
    }

    .cards_item {
        padding      : 1px 5px !important;
        margin-bottom: 6px !important;
    }
    .NtfctnCrd{
        h2 {
            font-size: 10px !important;
            padding: 0px !important;
        }

        h3 {
            font-size: 11px !important;
            padding  : 4px 0px !important;
        }

        h4 {
            font-size: 11px !important;
            padding  : 4px 0px !important;

            b:first-child {
                padding: 0px 3px;
                color  : #3bb556;
            }
        }
        h5 {
            font-size: 11px !important;   width: 100%;
        }
        h1{
            font-size: 10px !important;
            b:first-child{
              font-size: 10px !important;
            }
            b:nth-child(2){
              font-size: 8px !important;
            }
            
        }
        h6{
            font-size: 10px !important;
        }
    }
    .vehDsbrd {
        .crdBg {
            background: #fff;
            box-shadow: -1px 4px 8px #e0e0e0;
            padding   : 0px;
        }

        .crdBg:first-child,
        .crdBg:nth-child(3) {
            padding: 10px 20px;
        }
        
        .crdBg{
            h2 {
                font-size: 12px !important;
            }
            h3 {
                font-size: 11px !important;
                padding  : 4px 0px !important;
            }
    
            h4 {
                font-size: 11px !important;
                padding  : 4px 0px !important;
    
                b:first-child {
                    padding: 0px 3px;
                    color  : #3bb556;
                }
            }
            h5 {
                font-size: 11px !important;   width: 100%;
            }
            h1{
                font-size: 10px !important;
                b:first-child{
                  font-size: 10px !important;
                }
                b:nth-child(2){
                  font-size: 8px !important;
                }
                
            }
            h6{
                font-size: 10px !important;
            }
        }

        .colSctn {
            margin: auto;
        }

        

        .leaflet-touch .leaflet-bar a {
            width      : 20px !important;
            height     : 20px !important;
            line-height: 20px !important;
            font-size  : 14px !important;
        }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        // border          : 0px solid #584c4c !important;
        border-radius   : 2px !important;
        width           : 100% !important;
        height          : 30px !important;
        // border-right    : 0px !important;
        font-size: 12px;
    }
    
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height     : 30px !important;
        line-height: 30px !important;
        font-weight: 700 !important;
    }
    
    .ant-select-selection-placeholder {
        height     : 30px !important;
        line-height: 30px !important;
        opacity    : .8 !important;
    }
    
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 30px !important;
    }
    
    .drpLbl {
        font-size: 11px !important;
    }

    .srchCrdHdr {
        font-size: 13px !important;
    }

    .serchCardRightTxt {
        font-size: 11px !important;
    }

    .srchCrdBrctTxt {
        font-size: 11px !important;
    }

    .serchCardLeftTxt {
        font-size: 11px !important;
    }

    .mnsTxt {
        font-size: 12px !important;
    }

    nz-select {
        width: 145px;
    }


    .abs_cntr {
        ul:first-child {
            margin-right: 2px !important;
            padding     : 4px 2px !important;

            li {
                padding: 0px 10px !important;
            }

            h1 {
                font-size: 13px !important;
            }

            h2 {
                font-size: 10px !important;
            }
        }

        ul:nth-child(2) {
            padding: 2px !important;

            li {
                padding: 0px 13px !important;
            }

            h1 {
                font-size: 13px !important;
            }

            h2 {
                font-size: 10px !important;
            }
        }
    }

    body {
        font-size: 12px !important;
    }
    .tbl {
        .dx-datagrid .dx-datagrid-headers .dx-header-row>td {
            font-size     : 10px !important;
            padding-top   : 0.2rem !important;
            padding-bottom: 0.2rem !important;
        }
        .dx-datagrid .dx-column-lines>td {
            font-size: 11px !important;
            padding  : 1px 4px !important;
        }

        .dx-pager .dx-page-sizes {
            font-size: 10px !important;
        }

        .dx-pager .dx-page-sizes .dx-page-size {
            padding: 5px 6px 5px 6px !important;
        }

        .dx-pager .dx-pages .dx-page {
            padding: 2px 8px 2px 8px !important;
        }
    }

}

@media (min-width: 1024px) and (max-width: 1280px) {
    #tripsTrackedChart g,
    #distanceTavelledChart g,
    #tripsTavelledChart g,
    #tripsCtgryWseChart g,
    #distanceCtgryWseChart g,
    #lst10DysNtoprdTrpsChart g,
    #lastSevenDaysChart g,
    #lastSevenDaysDistanceChart g,#ctgrytripsTavelledChart,
    #distanceChart,
    #tripChart {
        font-size: 10px !important;
    }
    .slctlbl {
        font-size: 11px !important;
    }
    .crd-hding {
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    .side-nav {
        top: 60px !important;
    }
    .dx-button-has-icon .dx-button-content {
        padding: 5px !important;
    }

    .dx-datagrid-export-button .dx-icon-export-to {
        font: 15px/18px DXIcons !important;
    }

    .ant-picker,
    .ant-picker-input>input {
        font-size  : 11px !important;
        height     : 32px !important;
    }
    // .common-layout .page-container {
    //     // margin-left: -30px !important;
    // }
    .menu-full {
        width: 160px !important;
      }
      
      .menu-minified {
        width: 70px !important;
      }
    .side-nav {
        // width: 160px !important;
        .r_sde{
            // width: 160px !important;
        }
        .nav-menu {
            padding-bottom: 20% !important;
            margin-bottom : 20% !important;
            width         : 70px !important;

            .nav-item {
                padding: 2px 4px !important;
                margin : 10px 0px !important;

                p {
                    font-size: 8px !important;

                    span:nth-child(2) {
                        margin-top: -13px !important;
                    }
                }

                h1 {
                    font-size    : 18px !important;
                    margin-bottom: 2px !important;
                }
            }

            .nav-item:hover {
                .nav-sbmenu {
                    .nav-sbitem {
                        div {
                            line-height: 10px !important;

                            span {
                                font-size: 10px !important;
                                line-height: 12px !important;
                            }
                        }
                    }
                }
            }
        }

        .nav-sbmenu {
            .nav-sbitem {
                padding: 8px 70px 8px 16px !important;

                div {
                    line-height: 10px !important;

                    span {
                        font-size: 10px !important;
                    }
                }
            }

            .nav-sbitem:after {
                height: 10px !important;
                width : 10px !important;
                top   : 12px !important;
            }

            .nav-sbitem:before {
                left : 4px !important;
                width: 2px !important;
            }

            .nav-sbitem.active span {
                font-size: 11px !important;
            }
        }
    }

    .hdr_mdl {
        img {
            width : 50px !important;
            height: 50px !important;
        }

        span:nth-child(2) {
            h1 {
                font-size: 16px !important;
            }

            h2 {
                font-size: 10px !important;
            }
        }
    }

    .hdrBar {
        margin: 8px 2px !important;

        h1 {
            font-size   : 15px !important;
            padding-left: 10px !important;
        }

        span {
            padding: 1px 7px 0px 5px!important;
            font-size: 11px !important;
        }

        .ant-picker {
            background   : #ffffff;
            border       : 1px solid #4f61d1;
            border-radius: 2px !important;
            height       : 30px !important;
            transition   : border .3s, box-shadow .3s;
        }

    }

    .inptCls .prmry,
    .inptCls .scndry {
        width  : 10px !important;
        height : 10px !important;
        margin : 0px 5px !important;
        padding: 6px !important;
    }

    .sdr {
        height  : 100vh;
        overflow: auto !important;
    }

    .main {
        .card_grand_title {
            font-size  : 11px !important;
            font-weight: 700 !important;
        }

        .card_title {
            font-size  : 14px !important;
            font-weight: 700 !important;
        }

        .card_text {
            font-size    : 10px !important;
            margin-bottom: 2px !important;
            font-weight  : 500 !important;
        }

        .card_sub_title {
            font-size     : 12px !important;
            font-weight   : 700 !important;
            letter-spacing: .2px !important;
            word-break    : break-word !important;
        }

        .card_sub_text {
            font-size    : 10px !important;
            margin-bottom: 0px !important;
            font-weight  : 400 !important;
        }

        .pt-20 {
            padding-top: 22% !important;
        }

        h1 {
            font-size: 14px !important;
        }
        // .card_g {
        //     h1{
        //         font-size: 11px !important;
        //     }
        //     .crdHdr{
        //         font-size: 12px !important;
        //         width:100%;
        //         // text-align: center !important;
        //         // border-bottom:1px solid white;
        //     }
        // }
       

        .card_content {
            padding        : 0px 15px !important;
            //   background: linear-gradient(to bottom left, #ef8d9c 40%, #ffc39e 100%);
        }

        .dx-datagrid-group-panel .dx-group-panel-message {
            display: none !important;
        }
    }
    .wd-28 {
        width    : 28.888% !important;
        max-width: 28.888% !important;
        flex     : 0 0 28% !important;
    }

    .cards_item {
        padding      : 1px 5px !important;
        margin-bottom: 6px !important;
    }
    .NtfctnCrd{
        h2 {
            font-size: 10px !important;
            padding: 0px !important;
        }

        h3 {
            font-size: 11px !important;
            padding  : 4px 0px !important;
        }

        h4 {
            font-size: 11px !important;
            padding  : 4px 0px !important;

            b:first-child {
                padding: 0px 3px;
                color  : #3bb556;
            }
        }
        h5 {
            font-size: 11px !important;   width: 100%;
        }
        h1{
            font-size: 10px !important;
            b:first-child{
              font-size: 10px !important;
            }
            b:nth-child(2){
              font-size: 8px !important;
            }
            
        }
        h6{
            font-size: 10px !important;
        }
    }
    .vehDsbrd {
        .crdBg {
            background: #fff;
            box-shadow: -1px 4px 8px #e0e0e0;
            padding   : 0px;
        }

        .crdBg:first-child,
        .crdBg:nth-child(3) {
            padding: 10px 20px;
        }
        
       
        .crdBg{
            h2 {
                font-size: 12px !important;
            }
            h3 {
                font-size: 11px !important;
                padding  : 4px 0px !important;
            }
    
            h4 {
                font-size: 11px !important;
                padding  : 4px 0px !important;
    
                b:first-child {
                    padding: 0px 3px;
                    color  : #3bb556;
                }
            }
            h5 {
                font-size: 11px !important;   width: 100%;
            }
            h1{
                font-size: 10px !important;
                b:first-child{
                  font-size: 10px !important;
                }
                b:nth-child(2){
                  font-size: 8px !important;
                }
                
            }
            h6{
                font-size: 10px !important;
            }
        }
        .colSctn {
            margin: auto;
        }

        .leaflet-touch .leaflet-bar a {
            width      : 20px !important;
            height     : 20px !important;
            line-height: 20px !important;
            font-size  : 14px !important;
        }

        ::ng-deep {
            .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                height          : 30px !important;
                padding         : 0px 11px !important;
                background-color: #eeeeee !important;
                border          : 1px solid #eeeeee !important;
            }

            .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
            .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
                color      : #272728 !important;
                font-weight: 500;
                font-size  : 12px !important;
            }
        }
    }

    .drpLbl {
        font-size: 11px !important;
    }

    .srchCrdHdr {
        font-size: 13px !important;
    }

    .serchCardRightTxt {
        font-size: 10px !important;
    }

    .srchCrdBrctTxt {
        font-size: 11px !important;
    }

    .serchCardLeftTxt {
        font-size: 11px !important;
    }

    .mnsTxt {
        font-size: 12px !important;
    }

    nz-select {
        width: 145px;
    }


    .abs_cntr {
        ul:first-child {
            margin-right: 2px !important;
            padding     : 4px 2px !important;

            li {
                padding: 0px 10px !important;
            }

            h1 {
                font-size: 13px !important;
            }

            h2 {
                font-size: 10px !important;
            }
        }

        ul:nth-child(2) {
            padding: 2px !important;

            li {
                padding: 0px 13px !important;
            }

            h1 {
                font-size: 13px !important;
            }

            h2 {
                font-size: 10px !important;
            }
        }
    }

    body {
        font-size: 12px !important;
    }
    .tbl {
        .dx-datagrid .dx-datagrid-headers .dx-header-row>td {
            font-size     : 10px !important;
            padding-top   : 0.5rem !important;
            padding-bottom: 0.5rem !important;
        }

        .dx-datagrid .dx-column-lines>td {
            font-size: 11px !important;
            padding  : 1px 4px !important;
        }

        .dx-pager .dx-page-sizes {
            font-size: 10px !important;
        }

        .dx-pager .dx-page-sizes .dx-page-size {
            padding: 5px 6px 5px 6px !important;
        }

        .dx-pager .dx-pages .dx-page {
            padding: 2px 8px 2px 8px !important;
        }
    }
}

@media (min-width: 1281px) and (max-width: 1440px) {
    .main-header-logo {
        height    : auto !important;
        margin-top: 0%;
        width     : auto;
    }
    #tripsTrackedChart g,
    #distanceTavelledChart g,
    #tripsTavelledChart g,
    #tripsCtgryWseChart g,
    #distanceCtgryWseChart g,
    #lst10DysNtoprdTrpsChart g,
    #lastSevenDaysChart g,
    #lastSevenDaysDistanceChart g,#ctgrytripsTavelledChart,
    #distanceChart,
    #tripChart {
        font-size: 10px !important;
    }
    .slctlbl {
        font-size: 11px !important;
    }
    .crd-hding {
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    .side-nav {
        top: 60px !important;
    }
    .dx-button-has-icon .dx-button-content {
        padding: 5px !important;
    }

    .dx-datagrid-export-button .dx-icon-export-to {
        font: 15px/18px DXIcons !important;
    }

    .ant-picker,
    .ant-picker-input>input {
        font-size  : 11px !important;
        height     : 32px !important;
    }
    // .common-layout .page-container {
    //     // margin-left: -30px !important;
    // }
    .menu-full {
        width: 160px !important;
      }
      
      .menu-minified {
        width: 70px !important;
      }
    .side-nav {
        // width: 160px !important;
        .r_sde{
            // width: 160px !important;
        }
        .nav-menu {
            padding-bottom: 20% !important;
            margin-bottom : 20% !important;
            width         : 70px !important;

            .nav-item {
                padding: 2px 4px !important;
                margin : 10px 0px !important;

                p {
                    font-size: 8px !important;

                    span:nth-child(2) {
                        margin-top: -13px !important;
                    }
                }

                h1 {
                    font-size    : 18px !important;
                    margin-bottom: 2px !important;
                }
            }

            .nav-item:hover {
                .nav-sbmenu {
                    .nav-sbitem {
                        div {
                            line-height: 10px !important;

                            span {
                                font-size: 10px !important;
                                line-height: 12px !important;
                            }
                        }
                    }
                }
            }
        }

        .nav-sbmenu {
            .nav-sbitem {
                padding: 8px 70px 8px 16px !important;

                div {
                    line-height: 10px !important;

                    span {
                        font-size: 10px !important;
                        line-height: 12px !important;
                    }
                }
            }

            .nav-sbitem:after {
                height: 10px !important;
                width : 10px !important;
                top   : 12px !important;
            }

            .nav-sbitem:before {
                left : 4px !important;
                width: 2px !important;
            }

            .nav-sbitem.active span {
                font-size: 10px !important;
            }
        }
    }

    .hdr_mdl {
        img {
            width : 50px !important;
            height: 50px !important;
        }

        span:nth-child(2) {
            h1 {
                font-size: 16px !important;
            }

            h2 {
                font-size: 10px !important;
            }
        }
    }
    .common-layout .page-header {
        margin-top: -14px!important;
    }
    .hdrBar {
        // margin: 8px 2px !important;
        // margin-top: -15px !important;

        // h1 {
        //     font-size   : 17px !important;
        //     padding-left: 10px !important;
        // }

        span {
            // padding  : 8px 5px 5px 5px !important;
            padding: 1px 7px 0px 5px!important;
            font-size: 11px !important;
        }

        

        .ant-picker {
            background   : #ffffff;
            border       : 1px solid #4f61d1;
            border-radius: 2px !important;
            height       : 30px !important;
            transition   : border .3s, box-shadow .3s;
        }

    }

    .inptCls .prmry,
    .inptCls .scndry {
        width  : 10px !important;
        height : 10px !important;
        margin : 0px 5px !important;
        padding: 6px !important;
    }

    .sdr {
        height  : 100vh;
        overflow: auto !important;
    }

    .main {
        .card_grand_title {
            font-size: 14px !important;
        }

        .card_title {
            font-size  : 16px !important;
            font-weight: 700 !important;
        }

        .card_text {
            font-size    : 13px !important;
            margin-bottom: 2px !important;
            font-weight  : 500 !important;
        }

        .card_sub_title {
            font-size     : 12px !important;
            font-weight   : 700 !important;
            letter-spacing: .2px !important;
            word-break    : break-word !important;
        }

        .card_sub_text {
            font-size    : 13px !important;
            margin-bottom: 0px !important;
            font-weight  : 400 !important;
        }

        .pt-20 {
            padding-top: 22% !important;
        }

        // h1 {
        //     font-size: 14px !important;
        // }

        // .card_g {
        //     h1 {
        //             font-size: 16px !important;
        //             font-weight: 600 !important;
        //             text-align: center!important;
        //     }

        //     h1.card_sub_title {
        //         font-size     : 10px !important;
        //         font-weight: 600 !important;
        //         text-align: center!important;

        //     }
            
        //     .crdHdr{
        //         font-size: 12px !important;
        //         width:100%;
        //         // text-align: center !important;
        //         // border-bottom:1px solid white;
        //     }
        // }

        .tbl .dx-datagrid .dx-datagrid-headers .dx-header-row>td {
            font-size: 11px !important;
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
        }

        .tbl .dx-datagrid .dx-column-lines>td {
            font-size: 13px !important;
        }

        .card_content {
            padding        : 4px 4px !important;
            //   background: linear-gradient(to bottom left, #ef8d9c 40%, #ffc39e 100%);
        }

        .dx-datagrid-group-panel .dx-group-panel-message {
            display: none !important;
        }
    }
    .NtfctnCrd{
        h2 {
            font-size: 10px !important;
            padding: 0px !important;
        }

        h3 {
            font-size: 11px !important;
            padding  : 4px 0px !important;
        }

        h4 {
            font-size: 11px !important;
            padding  : 4px 0px !important;

            b:first-child {
                padding: 0px 3px;
                color  : #3bb556;
            }
        }
        h5 {
            font-size: 11px !important;    width: 100%;
        }
        h1{
            font-size: 10px !important;
            b:first-child{
              font-size: 10px !important;
            }
            b:nth-child(2){
              font-size: 8px !important;
            }
            
        }
        h6{
            font-size: 10px !important;
        }
    }
    .vehDsbrd {
        .crdBg {
            background: #fff;
            box-shadow: -1px 4px 8px #e0e0e0;
            padding   : 0px;
        }

        .crdBg:first-child,
        .crdBg:nth-child(3) {
            padding: 10px 20px;
        }

        
        .crdBg{
            h2 {
                font-size: 12px !important;
            }
            h3 {
                font-size: 11px !important;
                padding  : 4px 0px !important;
            }
    
            h4 {
                font-size: 11px !important;
                padding  : 4px 0px !important;
    
                b:first-child {
                    padding: 0px 3px;
                    color  : #3bb556;
                }
            }
            h5 {
                font-size: 11px !important;   width: 100%;
            }
            h1{
                font-size: 10px !important;
                b:first-child{
                  font-size: 10px !important;
                }
                b:nth-child(2){
                  font-size: 8px !important;
                }
                
            }
            h6{
                font-size: 10px !important;
            }
        }
        .colSctn {
            margin: auto;
        }

        .leaflet-touch .leaflet-bar a {
            width      : 20px !important;
            height     : 20px !important;
            line-height: 20px !important;
            font-size  : 14px !important;
        }
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        // border          : 0px solid #584c4c !important;
        border-radius   : 2px !important;
        width           : 100% !important;
        height          : 30px !important;
        // border-right    : 0px !important;
        font-size: 12px;
    }
    
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height     : 30px !important;
        line-height: 30px !important;
        font-weight: 700 !important;
    }
    
    .ant-select-selection-placeholder {
        height     : 30px !important;
        line-height: 30px !important;
        opacity    : .8 !important;
    }
    
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 30px !important;
    }
    
    .drpLbl {
        font-size: 11px !important;
    }

    .srchCrdHdr {
        font-size: 13px !important;
    }

    .serchCardRightTxt {
        font-size: 11px !important;
    }

    .srchCrdBrctTxt {
        font-size: 11px !important;
    }

    .serchCardLeftTxt {
        font-size: 11px !important;
    }

    .mnsTxt {
        font-size: 12px !important;
    }

    nz-select {
        width: 145px;
    }

    .abs_cntr {
        ul:first-child {
            margin-right: 2px !important;
            padding     : 4px 2px !important;

            li {
                padding: 0px 10px !important;
            }

            h1 {
                font-size: 13px !important;
            }

            h2 {
                font-size: 12px !important;
            }
        }

        ul:nth-child(2) {
            padding: 2px !important;

            li {
                padding: 0px 13px !important;
            }

            h1 {
                font-size: 15px !important;
            }

            h2 {
                font-size: 12px !important;
            }
        }
    }

    body {
        font-size: 12px;
    }
}

.ant-select-multiple .ant-select-selector {
    height          : 32px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    // border          : 0px solid #584c4c !important;
    // border-radius   : 2px !important;
    width           : 100% !important;
    height          : 40px;
    border-radius: 3px !important;
    // border-right    : 0px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height     : 40px;
    line-height: 40px ;
    font-weight: 700 !important;
}

.ant-select-selection-placeholder {
    height     : 40px;
    line-height: 40px;
    opacity    : .8 !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px;
}

.tbl {
    .dx-datagrid-content .dx-datagrid-table {
        background: #ffffff !important;
    }
}

.main,
.hdrBar {
    .ant-picker-input:hover .ant-picker-clear {
        display: none !important;
    }

    nz-date-picker,
    nz-range-picker {
        width        : 50%;
        background   : #ffffff;
        border       : 1px solid #dadada !important;
        border-radius: 5px !important;
        height: 32px;
    }

    .ant-select-clear {
        display: none !important;
    }
}

// .ant-picker  .ant-picker-input{
//     width: 100% !important;
//     border-radius: 5px !important;
//     height: 2.7rem !important;
//     background: #f2f2f2;
//     border: none;
// }
.mg-a {
    margin: auto;
}


.DshClntHdr {
    h1 {
        margin        : 0px;
        font-size     : 26px;
        letter-spacing: .6px;
    }

    h2 {
        text-align    : center;
        font-size     : 17px;
        color         : #285fff;
        letter-spacing: .6px;
    }
}

.tbl {
    .dx-datagrid-rowsview .dx-datagrid-content{
        margin-bottom: 8px;
    }
    .dx-datagrid .dx-datagrid-headers .dx-header-row>td {
        background    : #2d82eb !important;
        color         : #fff !important;
        font-weight   : 600 !important;
        font-size     : 13px !important;
        text-transform: uppercase !important;
        padding-top   : 5px !important;
        padding-bottom: 5px !important;
        // border-bottom : #e2e2e2 solid 0px;
        border-top    : none;
    }

    .dx-datagrid .dx-column-lines>td {
        border-left   : 1px solid #f5f5f5 !important;
        border-right  : 1px solid #f5f5f5 !important;
        font-size     : 14px !important;
        color         : #141414;
        vertical-align: middle !important;
        font-weight   : 400;
        padding       : 3px 3px;
    }

    .dx-datagrid.dx-datagrid-borders>.dx-datagrid-pager {
        margin-top: 0px !important;
    }

    .tblEdtBtn {
        color         : #1565c0;
        font-weight   : 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align    : center;

        i {
            font-size: 20px;
        }

        p {
            
            margin: 0px;
    font-size: 10px;
    color: #1e88e5;
    font-weight: 400;
    text-transform: capitalize;
        }
    }
}
.dashbSlider.ant-drawer-body {
        padding: 12px !important;
    }
    .dashbSlider.ant-drawer-header{
        display: none !important;
    }

    // // sidemenu --------------------------------------------

    // .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    //     // background-color: #dfe2fc !important;
    //     background-color: #e8f5e9 !important;
    //     // background-color: #FFC107 !important;
    //     border-radius: 2px;
    //     // border-right: 3px solid #bec4f8;
    // }
    // .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
    //     // color: #ffffff!important;
    //     color: #2e7d32 !important;
    //     border-radius: 6px;
    //     font-weight: 500;
    // }
    // .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover {
    //     color: #2e7d32 !important;
    //     border-radius: 6px;
    //     font-weight: 500;
    // }
    // .ant-menu:not(.ant-menu-dark) a {
    //     font-size: 12px !important;
    //     font-weight: 500;
    //     letter-spacing: .6px;
    //     color: #212121;
    // }
    // .ant-menu-inline .ant-menu-item:hover {
    //     color: #2e7d32 !important;
    //     border-radius: 6px;
    //     font-weight: 500;
    // }
    // .ant-menu-inline .ant-menu-item {
    //     height: 45px !important;
    //     line-height: 45px !important;
    // }
    
    .dx-datagrid-text-content {
        overflow: hidden!important;
        text-overflow: clip!important;
    } 


    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a{
        color: #ffffff !important;
        font-weight: 500;
    }


    @media only screen and (max-width: 992px){
        .common-layout .page-container {
            padding-left: 0px ;
        }
    }
    .disbledtxt{
        border: 1px solid #d9d9d9;
        margin-right: 10px;
        height: 2.2rem;
        border-radius: 3px;
        background: #f9f9f9;
        text-align: left;
        line-height: 2rem;
        padding-left: 15px;
        opacity: .8;
    }

    ::ng-deep.ant-select-disabled .ant-select-arrow {
        display: none!important;
    }

 .ant-select-disabled {
    ::ng-deep.ant-select-arrow {
        display: none!important;
    }
    }

    @media only screen and (min-width: 992px){
.common-layout.is-folded .page-container {
    padding-left: 60px;
}
    }

@media only screen and (min-width: 320px)  and (max-width:500px){
    .cards_item3{
       width: 100%;
    }
    .cards_item1{
    
        width: 100%;
    }
}
.ps{
    background: black;
    opacity: 0.95;
}
.dxgridpdng{
    border-top: 2px solid #323f44;
    padding: 10px;
    background: white;
    box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.18), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.11);
    border-radius: 0px 0px 6px 6px;
    margin-bottom: 20px;
    height:98%;
    padding-top:0px;
    .dx-datagrid .dx-column-lines > td {
        border-left: 1px solid #f5f5f5 !important;
        border-right: 1px solid #f5f5f5 !important;
        font-size: 14px !important;
        color: #141414;
        vertical-align: middle !important;
        font-weight: 400;
        padding: 8px 3px;
    }

  .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
    //    background: #493f87 !important;
       background: #0099ac !important;
        color: #fff !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        text-transform: uppercase !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
       
    }

}
  .cvrgrdsbrd{
  .dx-datagrid .dx-column-lines > td:last-child {
     
        font-weight: 500;
    }
  }
  .znewsecvrges{
    .dx-datagrid .dx-column-lines > td:last-child {
       
          font-weight: 500;
      }
    }

    .ulbsgridcvrge{
        .dx-datagrid .dx-column-lines > td:nth-child(7) {
       
            font-weight: 500;
        } 
    }
    .svmcvrgedshbrd{
        .dx-datagrid .dx-column-lines > td:nth-child(12) {
       
            font-weight: 500;
        } 
    }


    .bgwhite .dx-datagrid{
        background-color: transparent !important;
    }
    .bgwhite .dx-toolbar {
        background-color: transparent !important;
    }

    .bgwhite .dx-datagrid-content {
        background-color: #fff !important;
    }

    .prkngdtls{
        .ant-modal-title {
            color: #d51920!important;
        }
    }
    .ant-menu-item-selected .side-menu-icon {
        background: #d0ebec;
        color: black;
      }
      
      .ant-menu-item-selected .side-menu-icon-chld .anticon {
        background: #d0ebec;
        color: black;
      }


      .header-borders{
        // border-bottom: 1px solid #f8f8f8;
        // margin-top: -34px;
        border-bottom: 0px solid #f8f8f8;
        margin-top: -12px;
        // padding-left: 33px;
      }

      .mdlcls{
.ant-modal-header{
    background: #2988df;
}
      }
.ant-modal-title {
    color: #ffffff;
}
      .prfcrd {
        .ant-card-head-title {
           box-shadow:none!important; 
           padding: 10px 0;
           color: #202d4b;
           text-shadow: none;
           font-size: 17px;
    font-weight: 600;
        }
      }

      .asrtcrddvdr{
        .ant-divider-inner-text {
            padding: 0 0.5em;
            color: #03A9F4;
        }
        .ant-divider-horizontal.ant-divider-with-text {
            color: rgb(0 0 0 / 12%);
        }
      }


      .underline{

        .dx-datagrid .dx-datagrid-content .dx-column-lines > td:nth-child(2){
        color: #03A9F4;
        text-decoration: underline;
        cursor: pointer;
        }
        .dx-datagrid .dx-datagrid-content .dx-header-row > td:nth-child(2){
            color: #000000;
            }
       
        
      }

      .elctncrd{
        .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
            border-bottom: 1px solid #ddd;
            vertical-align: middle;
                color: #000000;
        }

        .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(1) {
            background: #B6C9D9;
        }
        .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(2) {
            background: #B6C9D9;
        }

        .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(3) {
            background: #DBD7D7;
        }
        .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(4) {
            background: #C7CEEA;
        }
        .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(5) {
            background: #C1DBCC;
        }
        // .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(6) {
        //     background: #DBD7D7;
        // }

        // .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(7) {
        //     background: #C7CEEA;
        // }
        // .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(8) {
        //     background: #C7CEEA;
        // }
        // .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(9) {
        //     background: #C7CEEA;
        // }
        // .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(10) {
        //     background: #C7CEEA;
        // }
        // .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(11) {
        //     background: #C1DBCC;
        // }



        .dx-datagrid .dx-datagrid-headers tr:nth-child(2){
            td:nth-child(1){
                background: #DBD7D7;
            }
            td:nth-child(2){
                background: #DBD7D7;
            }
            td:nth-child(3){
                background: #DBD7D7;
            }
            td:nth-child(4){
                background: #DBD7D7;
            }
            td:nth-child(5){
            background: #C7CEEA;
            }
            td:nth-child(6){
                background: #C7CEEA;
                }
                td:nth-child(7){
                    background: #C7CEEA;
                    }
                    td:nth-child(8){
                        background: #C7CEEA;
                        }
        }


        .dx-datagrid .dx-column-lines > td:last-child {
            background: #E1B8B8;
        }
        .dx-datagrid .dx-datagrid-content .dx-column-lines > td:nth-child(2){
        color: #03A9F4;
        text-decoration: underline;
        cursor: pointer;
        }
        .dx-datagrid .dx-datagrid-content .dx-header-row > td:nth-child(2){
            color: #000000;
            }
       
        
      }

      .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
        vertical-align: middle!important;
    }

    .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
        // background: #e5debd;
        
        color: #fff;
        font-weight: 500;
        font-size: 13px;
        margin-right: 4px;
        text-align: center !important;
        text-transform: capitalize !important;
        outline: 0;
    }

    .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
        border-bottom: 1px solid #ddd;
        color: #fff !important;
        vertical-align: middle;
        font-family: Inter, sans-serif;
        text-transform: capitalize;
    }

    .astIsuGrid {
        .dx-datagrid-content table .dx-row-lines td:nth-child(3){
            color: #1890ff;
            font-weight: 500;
        }

        .dx-datagrid-content table tr.dx-master-detail-row td:nth-child(3){
            color: #000000!important;
            font-weight: 400!important;
        }
    }
    

    .evmtabs{
        .ant-tabs-nav-wrap{
            justify-content: center;
        }
    }
    .stshdrclr{
        // background: #bde5de!important;
        background: #bddfe5 !important;
    }

.ntstrdtdclr{
    // background: #d0f2fb !important;
   
    background: #bddae5 !important;
}
.inprgrsClr{
    // background: #ffeed4 !important;
    background: #e5dbbd !important;
}
.cmpltdClr{
    // background: #cff3dc !important;
    background: #bde5c0 !important;
}


.elctncrd2{
//     .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
//         border-bottom: 1px solid #ddd;
//         vertical-align: middle;
//             color: #000000;
//     }
  
//     .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(1) {
//         // background: #B6C9D9;
//         background: #ffc284;
//     }
//     .dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(2) {
//       background: #ffc284;
//     }
.dx-datagrid .dx-datagrid-headers .dx-header-row > td:nth-child(4) {
   
    background: #bddfe5;
}
  
  
    .dx-datagrid .dx-datagrid-headers tr:nth-child(2){
        td:nth-child(1){
            // background: #DBD7D7;
            background: #C7CEEA;
        }
        td:nth-child(2){
            // background: #DBD7D7;
            background: #e5dbbd;
        }
        td:nth-child(3){
            // background: #DBD7D7;
            background: #bde5c0;
        }
       
    }
  
    
  }
  .bluhdrgrid{
    .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
        background: #1969cd;
        color: #fff;
    }
    .dx-datagrid-total-footer > .dx-datagrid-content {
        background: #1969cd;
    }
    .dx-datagrid-summary-item {
        color: rgb(255 255 255);
    }
}

.imgevwrmdl{
    // width:90%!important;
    max-width: calc(65vw - 0px)!important;
    width:calc(65vw - 0px) !important;
}
.leaflet-bottom.leaflet-right{
visibility: hidden;
}


.w-80{
    .ftrDvlpd {
     img {
         width : 60%!important}
    }
    .sdeMnuIcn {
width: 28px;
height: 28px;
    }
    
}

.ant-switch{
    min-width: 50px!important;
    height: 22px!important;
}


.apsflgrid_sumry{
    .dx-datagrid-total-footer>.dx-datagrid-content {
        padding-top: 0px;
        padding-bottom: 0px;
        background-color: #fdeae5;
        border-top: 2px solid #f58e72;
        border-bottom: 2px solid #f58e72;
    }
    .dx-datagrid .dx-row>td {
        padding: 10px;
    }
    .dx-datagrid .dx-row-alt>td, .dx-datagrid .dx-row-alt>tr>td {
        background-color: #fffaf9;
    }
}

.cstmdrpdwn{
   
    padding: 10px !important;
    left: 0px !important;
    background-color: #ffb0b082;
    /* color: #fff !important; */
    border-radius: 4px;
    .ant-dropdown-menu-item {
        display: block!important;
    }
}

.wrkPrfleDrawer{
    .ant-drawer-header {
        text-align: left!important;
        background: #ffd2c4 !important;
        border-bottom: 2px solid #e87453 !important;
    }
    .ant-drawer-title {
        color: #000000 !important;
    }
    .anticon-close {
        color: #000000 !important;
    }
    
}
.ntfctn{
    display: flex;
    gap: 5px;
}
.ntfctn_title{
    font-weight: bold;
}